import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import React from "react";
import { useLocation } from "react-router-dom";
import NavHeader from "./NavHeader";

DashboardNavbar.propTypes = {
    onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
    const location = useLocation();

    const DRAWER_WIDTH = 271;
    const APPBAR_MOBILE = 160;
    const APP_BAR_DESKTOP = 137;

    const RootStyle = styled(AppBar)(({ theme }) => ({
        boxShadow: "none",
        borderBottom: "1px solid #EAECF0",
        backdropFilter: "blur(6px)",
        WebkitBackdropFilter: "blur(6px)",
        backgroundColor: "white",
        color: "black",
        [theme.breakpoints.down("lg")]: {
            zIndex: 999
        },
        [theme.breakpoints.up("lg")]: {
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
        },
    }));

    const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
        minHeight: APPBAR_MOBILE,
        alignItems: "flex-start",
        [theme.breakpoints.up("sm")]: {
            minHeight: APP_BAR_DESKTOP,
            padding: "20px 24px",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "10px 20px",
            minHeight: 60,
        },
    }));
    return (
        <RootStyle className="navbar_item">
            <ToolbarStyle>
                <IconButton
                    onClick={onOpenSidebar}
                    sx={{ mr: 1, color: "text.primary", display: { lg: "none" } }}
                >
                    <DensityMediumIcon sx={{ height: 20 }} />
                </IconButton>
                <NavHeader location={location.pathname} />
                <Box sx={{ flexGrow: 1 }} />
            </ToolbarStyle>
        </RootStyle>
    );
}
