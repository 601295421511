import React, { useState } from "react";
import { Box, Button, Grid, Stack, Typography, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import Field from "components/Field";
import { email, password, required } from "components/Field/validate";
import axios from "axios";
import toast from "react-hot-toast";
import axiosError from "utils/axiosError";
import { fileUploadRequest } from "../../../store/actions/auth";
import UploadFile from "../../../components/Common/UPLOAD/uploadFile";
import { screenAnalytics } from "fierbase";

const SignupForm = () => {
    React.useEffect(() => {
        screenAnalytics('Registartion');
    }, []);

    const navigate = useNavigate();
    const [file, setFile] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const handlefileChange = (val) => {
        if (val) {
            setIsLoading(true);
            fileUploadRequest(val).then((file) => {
                setFile(file);
                setTimeout(() => setIsLoading(false), 200);
            })
        }
    }
    const handleDeleteFileParant = () => {
        setFile(null);
    }

    return (
        <Grid
            item
            lg={7}
            md={12}
            sx={{
                width: "100%",
                margin: "auto",
                display: "flex",
            }}
        >
            <Stack
                sx={{ width: "100%", gap: { xs: "30px", md: "30px" } }}
                display="flex"
                alignItems="center"
                gap="96px"
            >
                <Stack width="100%">
                    <Typography component="span" variant="h3">
                        Sign up
                    </Typography>
                    <Typography
                        component="span"
                        variant="h6"
                        color="info.main"
                        sx={{ mt: 1 }}
                    >
                        {" "}
                        Get access
                    </Typography>
                </Stack>
                <Stack width="100%">
                    <Formik
                        initialValues={{
                            ProfileImage: "",
                            Email: "",
                            Password: "",
                            City: "",
                            Age: "",
                            Username: "",
                        }}
                        onSubmit={(values, { resetForm, setSubmitting }) => {
                            const data = { ...values };
                            if (file) {
                                data["ProfileImage"] = file.fileLink;
                            }
                            return axios.post(`/api/user/registation`, data)
                                .then((res) => {
                                    resetForm();
                                    toast.success(res?.data?.message);
                                    navigate("/login");
                                })
                                .catch((err) => axiosError(err));
                        }}
                    >
                        {(props) => {
                            const { handleSubmit, isSubmitting } = props;
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            maxHeight: 120,
                                            objectFit: "contain",
                                            mb: 3
                                        }}
                                    >
                                        <UploadFile
                                            isLoading={isLoading}
                                            profilePhoto={file ? file.fileLink : null}
                                            uploadIcon={false}
                                            isSubmitBtn={false}
                                            handlefileChange={handlefileChange}
                                            handleDeleteFileParant={handleDeleteFileParant}
                                        />
                                    </Box>
                                    <Field
                                        type="text"
                                        inputLabel="Username*"
                                        placeholder="Enter your Username"
                                        name="Username"
                                        validate={required}
                                    />
                                    <Field
                                        type="text"
                                        inputLabel="Email*"
                                        placeholder="Enter your email"
                                        name="Email"
                                        validate={email}
                                    />
                                    <Field
                                        type="text"
                                        inputLabel="City"
                                        placeholder="Enter your City"
                                        name="City"
                                        validate={required}
                                    />
                                    <Field
                                        type="number"
                                        inputLabel="Age"
                                        placeholder="Enter your Age"
                                        name="Age"
                                        min={0}
                                        max={100}
                                        validate={required}
                                    />
                                    <Field
                                        type="password"
                                        inputLabel="Password*"
                                        placeholder="Create a password"
                                        name="Password"
                                        validate={password}
                                    />
                                    <Stack width="100%" mb={2}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            disabled={isSubmitting}
                                        >
                                            {" "}
                                            Get started
                                        </Button>
                                    </Stack>
                                </form>
                            );
                        }}
                    </Formik>
                    <Typography
                        component="span"
                        textAlign="center"
                        variant="body2"
                        color="info.main"
                        fontWeight="400"
                    >
                        Already have an account?
                        <Typography
                            component="span"
                            variant="body2"
                            color="info.main"
                            fontWeight="600"
                            sx={{ cursor: "pointer" }}
                            ml={1}
                            onClick={() => navigate("/login")}
                        >
                            Log in
                        </Typography>
                    </Typography>
                </Stack>
            </Stack>
        </Grid>
    );
};

export default SignupForm;
