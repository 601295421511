import React from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Field from "components/Field";
import { matchesPassword, password, required } from "components/Field/validate";
import { Formik } from "formik";
import axios from "axios";
import toast from "react-hot-toast";
import axiosError from "utils/axiosError";
import Cookie from "../../../utils/cookie";
import { screenAnalytics } from "fierbase";

const ResetPasswordPage = () => {
  React.useEffect(() => {
    screenAnalytics('ForgotPassword');
  }, []);
  const navigate = useNavigate();
  return (
    <Grid
      item
      lg={7}
      md={12}
      sx={{
        width: "100%",
        margin: "auto",
        display: "flex",
      }}
    >
      <Stack
        sx={{ width: "100%", gap: { xs: "30px", md: "30px" } }}
        display="flex"
        alignItems="center"
        gap="96px"
      >
        {/* <Stack width="100%">
          <Typography
            component="span"
            sx={{
              cursor: "pointer",
            }}
          >
            <img
              src="/assets/logo/Logo.svg"
              alt="google"
              height="50"
              onClick={() => navigate("/dashboard")}
            />
          </Typography>
        </Stack> */}
        <Stack width="100%">
          <Typography component="span" variant="h3">
            Change Password
          </Typography>
          <Typography
            component="span"
            variant="h6"
            color="info.main"
            sx={{ mt: 1 }}
          >
            Please enter your details.
          </Typography>
        </Stack>
        <Stack width="100%">
          <Formik
            initialValues={{
              Otp: "",
              Password: "",
              CPassword: "",
            }}
            onSubmit={(values, { resetForm }) => {
              const data = { ...values };
              data.UserID = Cookie.get("userId");
              delete data.CPassword;
              return axios
                .post(`/api/user/resetPassword`, data)
                .then((res) => {
                  resetForm();
                  toast.success(res?.data?.message);
                  navigate("/login");
                })
                .catch((err) => axiosError(err));
            }}
          >
            {(props) => {
              const { handleSubmit, isSubmitting, values } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Field
                    type="number"
                    inputLabel="OTP"
                    placeholder="Enter your OTP here"
                    name="Otp"
                    validate={required}
                    maxLength={6}
                  />
                  <Field
                    type="password"
                    inputLabel="Password*"
                    placeholder="Create a password"
                    name="Password"
                    validate={password}
                  />
                  <Field
                    type="password"
                    inputLabel="Confirm Password"
                    placeholder="Confirm Password"
                    name="CPassword"
                    validate={(value) => matchesPassword(value, values.Password)}
                  />
                  <Stack width="100%" mb={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                    <Typography
                      component="span"
                      textAlign="center"
                      variant="body2"
                      color="info.main"
                      fontWeight="400"
                      sx={{
                        cursor: "pointer",
                        marginTop: 3
                      }}
                      onClick={() => navigate("/login")}
                    >
                      Cancel
                    </Typography>
                  </Stack>
                </form>
              );
            }}
          </Formik>
        </Stack>
      </Stack>
    </Grid>
  );
};

export default ResetPasswordPage;
