import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const Card = styled(Box)(() => ({
    background: "#193f54",
    border: "1px solid #FFFFFF",
    borderRadius: "8px",
    padding: "5px 17px",
    cursor: "pointer",
    userSelect: "none"
}))

const SwipperContainer = () => {
    return (
        <Stack
            direction="column"
            alignItems="center"
            margin="auto"
            width="70%"
        >
            <Box
                component="img"
                sx={{
                    width: 350,
                    height: "1005",
                    objectFit: "contain",
                }}
                src="/assets/logo/Logo.svg"
                alt="WorkingIcon"
            />
            <Stack
                direction="row"
                justifyContent="center"
                flexWrap="wrap"
                gap={4}
                mt={3}
            >
                <Card display="flex" alignItems="center">
                    <img src="/assets/logo/iosIcon.svg" alt="iosIcon" />
                    <Box ml={1}>
                        <Typography color="common.white" variant="caption" fontWeight="fontWeightLight">
                            Download on the
                        </Typography>
                        <Typography color="common.white" variant="subtitle2" fontWeight="fontWeightMedium">
                            App Store
                        </Typography>
                    </Box>
                </Card>
                <Card display="flex" alignItems="center">
                    <img src="/assets/logo/android.svg" alt="android" />
                    <Box ml={1}>
                        <Typography color="common.white" variant="caption" fontWeight="fontWeightLight">
                            Download on the
                        </Typography>
                        <Typography color="common.white" variant="subtitle2" fontWeight="fontWeightMedium">
                            App Store
                        </Typography>
                    </Box>
                </Card>
            </Stack>
        </Stack>
    );
};

export default SwipperContainer;
