import React from "react";
import PropTypes from "prop-types";
import {
  connect,
  ErrorMessage,
  FieldArray,
  Field as FormikField,
} from "formik";
import { FormHelperText, InputLabel, Stack } from "@mui/material";
import {
  CustomInputComponent,
  CustomNumberComponent,
  CustomPasswordComponent,
  FormControlMultiSelect,
  FormControlSelect,
} from "./CustomInputComponent";
import { SwitchComponent, CheckboxComponent } from "./Switch";
import ListInput from "./FieldArray";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {DatePicker} from "@mui/x-date-pickers";
import moment from 'moment';

const Field = (props) => {
  const { type, formik, name, inputLabel } = props;
  const { errors, touched } = formik;
  const error = Boolean(errors[name] && touched[name]);

  let fieldItem = (
    <FormikField
        {...props}
        error={Boolean(error)}
        as={CustomInputComponent}
    />
  );

  if (type === "number") {
    fieldItem = (
      <FormikField
        {...props}
        error={Boolean(error)}
        as={CustomNumberComponent}
      />
    );
  }

  if (type === 'date') {
    const value = moment.utc(props.formik.values[props.name]);
    const touched = props.formik.touched[props.name];

    fieldItem = (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
              {...props}
              value={value ? value : null}
              onChange={(value)=> {
                props.formik.setFieldValue(props.name, value);
              }}
              slotProps={{
                textField: {
                  size:"small",
                  fullWidth: true,
                  variant: 'outlined',
                  error: !!(touched && !value),
                  sx:{
                    "& .MuiInputBase-root" : {
                      borderRadius:"8px"
                    }
                  }
                }
              }}
          />
          {(touched && !value) && <FormHelperText error={true}>This Field is Required</FormHelperText>}
        </LocalizationProvider>
    )
  }

  if (type === "password") {
    fieldItem = (
      <FormikField
        {...props}
        type={type}
        error={Boolean(error)}
        as={CustomPasswordComponent}
      />
    );
  }

  if (type === "file") {
    fieldItem = <FormikField type="file" {...props} error={Boolean(error)}/>;
  }

  if (type === "select") {
    fieldItem = (
      <FormikField {...props} error={Boolean(error)} as={FormControlSelect} />
    );
  }

  if (type === "multiselect") {
    fieldItem = (
      <FormikField
        {...props}
        error={Boolean(error)}
        as={FormControlMultiSelect}
      />
    );
  }

  if (type === "list") {
    const index = props.index;

    fieldItem = (
        <FieldArray
            {...props}
            render={(rest)=> <ListInput index={index} {...rest}/>}
        />
    );
  }

  if (type === "switch") {
    fieldItem = (
      <FormikField>
        {({ field }) => (
          <SwitchComponent
            field={{ ...field, name: props.name }}
            label={props.label}
          />
        )}
      </FormikField>
    );
  }

  if (type === "checkbox") {
    fieldItem = (
      <FormikField>
        {({ field }) => (
          <CheckboxComponent
            field={{ ...field, name: props.name }}
            label={props.label}
          />
        )}
      </FormikField>
    );
  }

  return (
    <Stack sx={props.sx} mb={props.mb}>
      {inputLabel && (
        <InputLabel
          sx={{ mb: 1, fontWeight: 500, color: "black", fontSize: 14 }}
        >
          {inputLabel}
        </InputLabel>
      )}
      {fieldItem}
      {type !== "list" && (
          <ErrorMessage
              name={name}
              render={(error) => (
                  <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
              )}
          />
      )}
    </Stack>
  );
};

Field.propTypes = {
  type: PropTypes.oneOf([
    "password",
    "text",
    "select",
    "switch",
    "checkbox",
    "file",
    "multiselect",
    "number",
    "date",
    "list",
  ]).isRequired,
  validate: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  inputLabel: PropTypes.string,
  sx: PropTypes.object,
  name: PropTypes.string.isRequired,
  notched: PropTypes.bool,
};
Field.defaultProps = {
  className: "mb-3",
  name: "default_name",
  sx: null,
  inputLabel: null,
  mb: 2,
};
export default connect(Field);
