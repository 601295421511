import {Button, Grid} from "@mui/material";
import _ from "lodash";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import {connect} from "formik";
import WithoutImageComponent from "./WithoutImageComponent";
import WithImageComponent from "./WithImageComponent";

const ListInput = (props) => {
    const {form, push, remove, index, formik: {setFieldValue,setFieldTouched,values}} = props;
    const name = `Questions[${index}]`;

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                {(_.get(form.values, props.name) || []).map((ele, key) => {
                    const isRightAnswer = _.get(form.values, `${name}.RightAnswer`) === (`Option${key + 1}`);

                    if (_.get(form.values, `${name}.IsImage`) === 1) {
                        return (
                            <WithImageComponent
                                isRightAnswer={isRightAnswer}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                remove={remove}
                                index={key}
                                count={index}
                                values={values}
                            />
                        )
                    } else {
                        return (
                            <WithoutImageComponent
                                isRightAnswer={isRightAnswer}
                                setFieldValue={setFieldValue}
                                remove={remove}
                                index={key}
                                count={index}
                            />
                        )
                    }
                })}
            </Grid>
            {/*<Button*/}
            {/*    variant="text"*/}
            {/*    onClick={() => push("", "")}*/}
            {/*    sx={{*/}
            {/*        textTransform: "capitalize", px: 2,*/}
            {/*        display: "flex", ml: "auto", mt: 2*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <AddIcon sx={{mr: 1}}/> Add Field*/}
            {/*</Button>*/}
        </React.Fragment>
    )
}

export default connect(ListInput)