import React from "react";
import { styled } from "@mui/material/styles";
import history from "components/history";
import Cookie from "utils/cookie";
import { useDispatch } from "react-redux";
import { setCurrentRoute } from "store/actions/auth";

const LayoutContent = ({ element: ReactComponent, noPadding = false }) => {
    const dispatch = useDispatch()
    const APP_BAR_DESKTOP = 137;

    const MainStyle = styled("div")(({ theme }) => ({
        flexGrow: 1,
        overflow: "auto",
        minHeight: "100%",
        paddingTop: noPadding ? 0 : 160,
        zIndex: noPadding ? 9000 : 9,
        [theme.breakpoints.down("lg")]: {
            zIndex: noPadding ? 400 : 9,
        },
        [theme.breakpoints.up("lg")]: {
            paddingTop: noPadding ? 0 : 137,
        },
        [theme.breakpoints.down("sm")]: {
            paddingTop: noPadding ? 0 : 65,
        },
    }));

    if (!Cookie.get("userId") || !Cookie.get("jwt")) {
        dispatch(setCurrentRoute(window.location.pathname));
        history.push("/login");
    }

    return (
        <MainStyle>
            <ReactComponent />
        </MainStyle>
    );
};

export default LayoutContent;
