import axios from "axios";
import axiosError from "utils/axiosError";
import toast from "react-hot-toast";
import history from "components/history";
import Cookie from "utils/cookie";


export const STORE_CURRENT_ROUTE = "store_current_route";
export function setCurrentRoute(url = "") {
    return {
        type: STORE_CURRENT_ROUTE,
        payload: url
    }
}

let cancelTokenSource = null;

export function authenticatedUser() {
    return (dispatch) => {
        const userId = Cookie.get("userId");
        const jwt = Cookie.get("jwt");
        const userType = Cookie.get("userType");
        if ((userId || jwt) && userType) {
            if (cancelTokenSource) {
                cancelTokenSource.cancel("Request canceled due to new request");
            }
            cancelTokenSource = axios.CancelToken.source();
            const role = userType.toLowerCase();
            axios.get(`/api/${role}/get${userType}Info`, {
                cancelToken: cancelTokenSource.token,
                params: { [userType + "ID"]: userId },
            })
                .then((response) => {
                    dispatch({
                        type: STORE_CURRENT_USER,
                        payload: response?.data?.data,
                    });
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                    } else {
                        if (error.response && error.response.data.message === "No token provided.") {
                            Cookie.delete("userId");
                            Cookie.delete("jwt");
                            history.push("/login");
                        }
                    }
                });
        } else {
            Cookie.delete("userId");
            Cookie.delete("jwt");
            history.push("/login");
        }
    };
}

export function updateProfile(values = {}) {
    return (dispatch, getState) => {
        values.UserID = Cookie.get("userId");
        const data = { ...values };
        return axios
            .put(`/api/user/updateUserInfo`, data)
            .then((res) => {
                if (res?.data?.error) {
                    dispatch(authenticatedUser());
                    return toast.success(res?.data?.message);
                }
                return toast.error(
                    res?.data?.message || "Something went wrong please try again"
                );
            })
            .catch((err) => axiosError(err));
    };
};

export const STORE_CURRENT_USER = "store_current_user";

export const login = (values = {}, resetForm) => {
    const device_token = Cookie.get("ftToken");
    const data = { DeviceToken: device_token, IsWeb: true, ...values };
    return (dispatch, getState) => {
        return axios
            .post(`/api/user/login`, data)
            .then((res) => {
                if (res?.data?.userType === "Admin") {
                    Cookie.save("userId", res?.data?.adminID);
                } else {
                    Cookie.save("userId", res?.data?.userID);
                }
                Cookie.save("userType", res?.data?.userType);
                Cookie.save("jwt", res?.data?.token);
                axios.defaults.headers.common["x-access-token"] = res?.data?.token;
                dispatch({
                    type: STORE_CURRENT_USER,
                    payload: res?.data?.data,
                });
                setTimeout(() => {
                    if (getState().auth.currentURL) {
                        history.push(getState().auth.currentURL);
                        dispatch(setCurrentRoute("/dashboard"))
                    } else {
                        history.push("/dashboard");
                    }
                    resetForm();
                    toast.success(res?.data?.message);
                }, 500)
            })
            .catch((err) => axiosError(err));
    };
};

export const LoginwithFierbase = (values = {}) => {
    const device_token = Cookie.get("ftToken");
    const data = { DeviceToken: device_token, ...values };
    return (dispatch, getState) => {
        return axios
            .post(`/api/user/login/firebase`, data)
            .then((res) => {
                if (res?.data?.userType === "Admin") {
                    Cookie.save("userId", res?.data?.adminID);
                } else {
                    Cookie.save("userId", res?.data?.userID);
                }
                Cookie.save("userType", res?.data?.userType);
                Cookie.save("jwt", res?.data?.token);
                axios.defaults.headers.common["x-access-token"] = res?.data?.token;
                dispatch({
                    type: STORE_CURRENT_USER,
                    payload: res?.data?.data,
                });
                setTimeout(() => {
                    if (getState().auth.currentURL) {
                        history.push(getState().auth.currentURL);
                        dispatch(setCurrentRoute("/dashboard"))
                    } else {
                        history.push("/dashboard");
                    }
                    toast.success(res?.data?.message);
                }, 500)
            })
            .catch((err) => axiosError(err));
    };
};


export const fileUploadRequest = (file = null) => {
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("FileName", file);
        return axios.post("/api/file/upload-file", formData)
            .then((response) => {
                resolve(response.data)
            })
            .catch((err) => reject(err));
    })
}