import axios from "axios";
import axiosError from "../../utils/axiosError";
import Cookie from "../../utils/cookie";
import { authenticatedUser } from "./auth";
import history from "../../components/history";
import toast from "react-hot-toast";

export const VIEW_USER = "view_user";
export const cleanupUser = () => {
    return (dispatch, getState) => {
        dispatch({
            type: VIEW_USER,
            payload: null,
        });
    }
}

export const viewUser = (id = "") => {
    return (dispatch, getState) => {
        const role = Cookie.get("userType").toLowerCase();
        return axios.get(`/api/${role}/getUserInfo?UserID=${id}`)
            .then((res) => {
                dispatch({
                    type: VIEW_USER,
                    payload: res?.data?.data,
                });
            })
            .catch((err) => axiosError(err));
    }
}
export const VIEW_GROUP = "view_group";
export const cleanupGroup = () => {
    return {
        type: VIEW_GROUP,
        payload: null
    }
}
export const viewGroup = (id = "") => {
    return (dispatch, getState) => {
        return axios.get(`/api/group/group-by-id?GroupID=${id}`)
            .then((res) => {
                dispatch({
                    type: VIEW_GROUP,
                    payload: res?.data?.finalResult,
                });
                return res?.data?.finalResult;
            })
            .catch((err) => axiosError(err));
    }
}

export const deleteGroup = (values = {}) => {
    return (dispatch, getState) => {
        const headers = { 'Content-Type': 'application/x-www-form-urlencoded', };
        const data = new URLSearchParams({
            'GroupID': values.GroupID,
        });

        return axios.delete(
            "/api/group/delete-group", {
            headers,
            data
        }).then((res) => {
            history.push("/group");
            toast.success(res?.data?.message);
        })
            .catch((err) => axiosError(err));
    }
}

export const deleteGroupUser = (values = {}) => {
    return (dispatch, getState) => {
        const data = new URLSearchParams({
            'GroupID': values.GroupID,
            'UserGroupID': values.UserGroupID
        });
        const headers = { 'Content-Type': 'application/x-www-form-urlencoded', };
        return axios.delete(
            "/api/group/delete-user", {
            headers,
            data
        })
            .then((res) => {
                dispatch(viewGroup(values.GroupID))
                toast.success(res?.data?.message);
            })
            .catch((err) => axiosError(err));
    }
}
export const GET_LEADERBOARD_DATA = "GET_LEADERBOARD_DATA";
export const getLeaderboardData = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: GET_LEADERBOARD_DATA,
            payload: data,
        });
    }
}
export const VIEW_GROUP_USER_PROFILE = "VIEW_GROUP_USER_PROFILE";
export const viewGroupUserProfile = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: VIEW_GROUP_USER_PROFILE,
            payload: data,
        });
    }
}
export const GET_USER_REPORT = 'GET_USER_REPORT';
export const getUserReport = () => {
    return (dispatch, getState) => {
        return axios.get(`/api/user/getUserReport`)
            .then((res) => {
                dispatch(authenticatedUser())
                dispatch({
                    type: GET_USER_REPORT,
                    payload: res.data.data,
                });
            })
            .catch((err) => axiosError(err));
    }
}