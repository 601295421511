import React from "react";
import { Box, CircularProgress, Stack } from "@mui/material";

const Loader = () => {
    return (
        <Box sx={{ height: "100vh", width: "100%", display: 'flex', alignItems: "center", justifyContent: "center" }}>
            <Stack direction="column" alignItems="center" justifyContent="center">
                <CircularProgress sx={{ mb: 2, height: 50, width: 50 }} />
                <img src="/assets/logo/Logo.svg" alt="logo" height="150px" />
            </Stack>
        </Box>
    );
};

export default Loader;
