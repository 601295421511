import axios from "axios";
import axiosError from "../../utils/axiosError";
import toast from "react-hot-toast";
import history from "../../components/history";
import Cookie from "../../utils/cookie";

export const getQuizLists = () => {
    return (dispatch, getState) => {
        return axios.get(`/api/quize/quize-list`)
            .then((res) => {
            })
            .catch((err) => axiosError(err));
    }
}
export const GET_USER_LIST =  'get_user_list'
export const getUserList = (page = 1) => {
    return (dispatch, getState) => {
        const payload ={limit:1,page}
        return axios.post("/api/admin/getUserList",payload)
            .then((res) => {
                dispatch({
                    type: GET_USER_LIST,
                    payload: res.data
                })
            })
            .catch((err) => axiosError(err));
    }
}

export const GET_USER_QUIZE_LIST = "get_user_quize_list"
export const GET_USER_QUIZE = "get_user_quize"
export const getUserQuizLists = () => {
    return (dispatch, getState) => {
        return axios.get(`/api/user/getQuize`)
            .then((res) => {
                dispatch({
                    type: GET_USER_QUIZE_LIST,
                    payload: res?.data?.data,
                });
                dispatch({
                    type: GET_USER_QUIZE,
                    payload: res?.data,
                });
            })
            .catch((err) => axiosError(err));
    }
}
export const saveQuestion = (data) => {
    return (dispatch, getState) => {
        return axios.post(`/api/user/saveQuestionResponse`, data)
            .then((res) => {})
            .catch((err) => axiosError(err));
    }
}

export const GET_CALENDER_LISTS = "get_calender_lists";
export const getCalenderLists = () => {
    return (dispatch, getState) => {
        return axios.get(`/api/admin/calender`)
            .then((res) => {
                dispatch({
                    type: GET_CALENDER_LISTS,
                    payload: res?.data,
                });
            })
            .catch((err) => axiosError(err));
    }
}

export const createNewQuiz = (data = {}, isUpdate = false) => {
    return (dispatch, getState) => {
        return axios({
            url: `/api/quize/${isUpdate ? "update-quize" : "create-quize"}`,
            method: isUpdate ? "PUT" : "POST",
            data: data
        }).then(() => {
            history.push("/dashboard");
            toast.success(`Quiz ${isUpdate ? "Updated" : "Created"} Successfully!`);
        }).catch((err) => axiosError(err));
    }
}

export const GET_QUIZ_BY_ID = "get_quiz_by_id";
export const getQuizById = (id = "") => {
    return (dispatch, getState) => {
        return axios.get(`/api/quize/quize-by-id?QuizeID=${id}`)
            .then((res) => {
                dispatch({
                    type: GET_QUIZ_BY_ID,
                    payload: res?.data.groupData[0],
                });
            })
            .catch((err) => axiosError(err));
    }
}


export const cleanupQuiz = () => {
    return {
        type: GET_QUIZ_BY_ID,
        payload: null
    }
}