import {STORE_CURRENT_ROUTE, STORE_CURRENT_USER} from "../actions/auth";
import {GET_CALENDER_LISTS, GET_QUIZ_BY_ID, GET_USER_LIST, GET_USER_QUIZE, GET_USER_QUIZE_LIST} from "../actions/quize";
import {
    GET_LEADERBOARD_DATA, GET_USER_REPORT, getUserReport,
    VIEW_GROUP,
    VIEW_GROUP_USER_PROFILE,
    VIEW_USER,
} from "../actions/user";

export const auth = (state = null, action) => {
    if (state === null) {
        return {
            user: null,
            userQuizData: null,
            userListData: null,
            calendarLists: null,
            currentQuiz: null,
            userQuizMessage: null,
            viewUserData: null,
            viewGroupData: null,
            getGroupData: [],
            viewLeaderboardData: [],
            groupUserProfileData: null,
            getUserReportData: null,
            currentURL:"/dashboard"
        }
    }

    switch (action.type) {
        case STORE_CURRENT_ROUTE:
            return {
                ...state,
                currentURL: action.payload
            }
        case STORE_CURRENT_USER:
            return {
                ...state,
                user: action.payload
            }
        case GET_USER_QUIZE_LIST:
            return {
                ...state,
                userQuizData:action.payload
            }
        case GET_USER_QUIZE:
            return {
                ...state,
                userQuizMessage:action.payload
            }
        case GET_USER_LIST:
            return {
                ...state,
                userListData:action.payload
            }
        case GET_CALENDER_LISTS:
            return {
                ...state,
                calendarLists:action.payload
            }
        case GET_QUIZ_BY_ID:
            return {
                ...state,
                currentQuiz:action.payload,
            }
        case VIEW_USER:
            return {
                ...state,
                viewUserData:action.payload,
            }
        case VIEW_GROUP:
            return {
                ...state,
                viewGroupData:action.payload,
            }
        case GET_LEADERBOARD_DATA:
            return {
                ...state,
                viewLeaderboardData:action.payload,
            }
        case VIEW_GROUP_USER_PROFILE:
            return {
                ...state,
                groupUserProfileData:action.payload,
            }
        case GET_USER_REPORT:
            return {
                ...state,
                getUserReportData:action.payload,
            }
        default:
            return state
    }
}