import React from 'react';
import {Grid, IconButton, Stack, Typography} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Field from "../../index";
import {required} from "../../validate";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const WithoutImageComponent = (props) => {
    const {isRightAnswer, index, remove,count, setFieldValue} = props;
    const name = `Questions[${count}]`;
    return (
        <Grid item md={3} sx={{position: "relative"}}>
            <Stack width="100%">
                <Typography
                    variant="subtitle2" mb={1}
                    sx={{display: "flex", alignItems: "center", cursor: "pointer"}}
                >
                    Answer {index + 1}*
                    <CheckCircleIcon
                        onClick={() => setFieldValue(name + ".RightAnswer", `Option${index + 1}`)}
                        sx={{
                            ml: 1,
                            fill: isRightAnswer ? "" : "#0F993E1A"
                        }}
                        fontSize="small"
                    />
                </Typography>
                <Field
                    type="text"
                    name={name + `options.${index}`}
                    size="small"
                    mb={0}
                    validate={required}
                    // validate={(value) => (index < 2 ? required(value) : null)}
                />
            </Stack>
            {/*{index > 1 && (*/}
            {/*    <IconButton*/}
            {/*        color="inherit" size="small"*/}
            {/*        onClick={() => remove(index)}*/}
            {/*        sx={{*/}
            {/*            position: "absolute",*/}
            {/*            right: -12,*/}
            {/*            top: 30*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <RemoveCircleIcon/>*/}
            {/*    </IconButton>*/}
            {/*)}*/}
        </Grid>
    );
};

export default WithoutImageComponent;