import React from 'react';
import {Box, Grid, IconButton, Stack, Typography} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {fileUploadRequest} from "store/actions/auth";
import _ from "lodash";

const WithImageComponent = (props) => {
    const {isRightAnswer, index,count, remove, setFieldValue,values} = props;
    const name = `Questions[${count}]`;
    const file = _.get(values, `${name}.images[${index}]`);
    return (
        <Grid item md={3} sx={{position: "relative"}}>
            <Stack width="100%">
                <Typography
                    variant="subtitle2" mb={1}
                    sx={{display: "flex", alignItems: "center", cursor: "pointer"}}
                >
                    Answer {index + 1}*
                    <CheckCircleIcon
                        onClick={() => setFieldValue(name + ".RightAnswer", `Option${index + 1}`)}
                        sx={{
                            ml: 1,
                            fill: isRightAnswer ? "" : "#0F993E1A"
                        }}
                        fontSize="small"
                    />
                </Typography>
                <Box
                    sx={{
                        border: "2px dashed #D9D9D9",
                        padding: "20px 35px 20px 35px"
                    }}
                    onClick={()=>document.getElementById(`${name}.images.${index}`).click()}
                >
                    {_.get(values,`${name}.images[${index}]`) ? (
                        <img
                            src={file} alt={`images[${index}]`}
                            style={{width:"100%"}}
                        />
                    ) : (
                      <>
                          <Stack justifyContent="center">
                              <img src="/assets/images/uploadFileIcon.svg" alt="uploadFileIcon" height={36}/>
                          </Stack>
                          <Typography display="flex" mt={1}>
                              <Typography variant="link" fontSize="14px" mr={1}>Upload a file</Typography>
                              <Typography variant="subtitle2">or drag and drop</Typography>
                          </Typography>
                          <Typography variant="overline" textAlign="center" display="block" color="#A7A7A7">
                              PNG, JPG, GIF up to 10MB
                          </Typography>
                      </>
                    )}
                </Box>
                <input
                    id={name + `.images.${index}`}
                    type="file"
                    multiple={false}
                    style={{display:"none"}}
                    onChange={(e) => {
                        fileUploadRequest(e.target.files[0]).then((file)=>{
                            setFieldValue(name + `.images[${index}]`, file["fileLink"]);
                        })
                    }}
                />
            </Stack>
            {/*{index > 1 && (*/}
            {/*    <IconButton*/}
            {/*        color="inherit" size="small"*/}
            {/*        onClick={() => remove(index)}*/}
            {/*        sx={{*/}
            {/*            position: "absolute",*/}
            {/*            right: -12,*/}
            {/*            top: 30*/}
            {/*        }}*/}
            {/*    >*/}
            {/*    <RemoveCircleIcon/>*/}
            {/*    </IconButton>*/}
            {/*)}*/}
        </Grid>
    );
};

export default WithImageComponent;