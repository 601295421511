import React from "react";
import { Grid, Typography } from "@mui/material";
import LoginForm from "./LoginForm";
import ForgetPasswordPage from "./ForgetPasswordPage";
import ResetPasswordFrom from "./ResetPassword";
import SignupForm from "./SignupForm";
import AuthSideBar from "components/AuthSideBar";

const AuthPage = () => {

    return (
        <Grid
            container
            component="main"
            sx={{ height: "100vh", overflow: "hidden" }}
        >
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    height: "calc(100vh - 0px)",
                    padding: { xs: "10px 20px", md: "20px 0" },
                    overflowY: "auto",
                    background: "white",
                }}
                display="flex"
            >
                {window.location.pathname === "/forget-password" && <ForgetPasswordPage />}
                {window.location.pathname === "/signup" && <SignupForm />}
                {window.location.pathname === "/login" && <LoginForm />}
                {window.location.pathname === "/reset-password" && <ResetPasswordFrom />}
                {/* <Typography
                    component="span"
                    variant="span"
                    color="info.main"
                    sx={{
                        position: "absolute",
                        bottom: 20,
                        left: 20,
                    }}
                >
                    © FanatIQ Trivia 2023
                </Typography> */}
            </Grid>
            <Grid
                item
                md={6}
                sx={{
                    display: {
                        xs: "none",
                        md: "flex",
                    },
                    background: "#002B42",
                    padding: "5%"
                }}
            >
                <AuthSideBar />
            </Grid>
        </Grid>
    );
};

export default AuthPage;
