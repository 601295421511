import React, { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { requestForToken, onMessageListener } from 'fierbase';

const Notification = () => {
    const [notification, setNotification] = useState({ title: '', body: '', image: '' });
    const notify = () => toast(<ToastDisplay />);
    function ToastDisplay() {
        return (
            <div style={{ padding: '10px', width: '300px' }}>
                {notification?.image && <img src={notification?.image} alt="logo" style={{ float: 'right', width: '42px', height: '42px', borderRadius: '50%', marginLeft: '10px' }} />}
                <p><b style={{ color: '#333' }}>{notification?.title}</b></p>
                <p style={{ margin: '0', padding: '5px 0' }}>{notification?.body}</p>
            </div>
        );
    };

    useEffect(() => {
        if (notification?.title) {
            notify()
        }
    }, [notification])

    requestForToken();

    onMessageListener()
        .then((payload) => {
            setNotification({ title: payload?.notification?.title, body: payload?.notification?.body, image: payload?.notification?.image });
        })
        .catch((err) => console.log('failed: ', err));
}

export default Notification;
