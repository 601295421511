import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import DashboardSidebar from "layout/DashboardSidebar";
import DashboardNavbar from "layout/DashboardNavbar";
import { authenticatedUser } from "store/actions/auth";
import { useDispatch } from "react-redux";

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
}));

export default function Dashboard() {
  const [open, setOpen] = React.useState(false);

  const disaptch = useDispatch();
  useEffect(() => {
    disaptch(authenticatedUser(true));
  }, []);

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <Outlet />
    </RootStyle>
  );
}
