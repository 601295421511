import { Checkbox, IconButton, InputAdornment, ListItemText, MenuItem, TextField } from "@mui/material";
import { useState } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Select, InputLabel, FormControl } from "@mui/material";

export const CustomInputComponent = (props) => {
    return (
        <TextField
            {...props}
            InputLabelProps={{ shrink: true }}
        />
    );
}

export const CustomFileComponent = (props) => {
    const {value,...rest} = props;
    return (
        <TextField
            {...rest}
            onChange={(e)=>{
                props.formik.setFieldValue(props.name, e.target.files[0]);
            }}
            InputLabelProps={{ shrink: true }}
        />
    );
}

export const CustomNumberComponent = (props) => {
    let value = props.value ? parseInt(props.value) : 0;
    return (
        <TextField
            {...props}
            sx={{
                "& .MuiInputBase-root" : {
                    paddingRight:0
                },
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                },
                "& input[type=number]": {
                    MozAppearance: "textfield",
                },
            }}
            onChange={(e)=> {
                value = parseInt(e.target.value);
                if (isNaN(value)) value = "";
                if (value < props.min) value = "";
                if (props.max && (value > props.max)) value = props.max;
                if (props.maxLength && value.toString().length > props.maxLength) {
                    value = value.toString().slice(0, props.maxLength);
                }
                props.formik.setFieldValue(props.name, value);
            }}
            onKeyDown={(event)=>{
                if(event.key==='.'){
                    event.preventDefault();
                }
            }}
            InputLabelProps={{ shrink: true }}
        />
    );
}

export const CustomPasswordComponent = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordField = () => {
        setShowPassword(!showPassword);
    }
    return (
        <TextField
            {...props}
            type={showPassword ? "text" : "password"}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={togglePasswordField}
                            edge="end"
                        >
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                    </InputAdornment>),
            }}
        />
    );
}

export const FormControlSelect = (props) => {
    const { children, selectsx, notched, ...rest } = props;
    return (
        <FormControl sx={{ opacity: rest.disabled ? 0.6 : 1, ...props.selectsx }} size={props.size}>
            {props.label && notched && <InputLabel shrink={!!notched}>{props.label}</InputLabel>}
            <Select
                {...rest}
                notched={!!notched}
                sx={{
                    '& .MuiSelect-select .notranslate::after': props.placeholder
                        ? {
                            content: `"${props.placeholder}"`,
                            opacity: 0.42,
                        }
                        : {},
                }}
            >
                {children}
            </Select>
        </FormControl>
    );
}


export const FormControlMultiSelect = (props) => {
    const { children, selectsx, notched, options, value, onChange, ...rest } = props;
    
    const handleChange = (event) => {
        const {
            target: { value },
          } = event;
      
          let duplicateRemoved = [];
      
          value.forEach((item) => {
            if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
              duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
            } else {
              duplicateRemoved.push(item);
            }
          });
        props.formik.setFieldValue(props.name, duplicateRemoved);
    };

    return (
        <FormControl sx={{ opacity: rest.disabled ? 0.6 : 1, ...props.selectsx }} size={props.size}>
            {props.label && notched && <InputLabel shrink={!!notched}>{props.label}</InputLabel>}
            <Select
                {...rest}
                notched={!!notched}
                multiple
                onChange={handleChange}
                sx={{
                    '& .MuiSelect-select .notranslate::after': props.placeholder
                        ? {
                            content: `"${props.placeholder}"`,
                            opacity: 0.42,
                        }
                        : {},
                }}
                value={value || []}
                renderValue={(selected) => selected.map((x) => x.name).join(', ')}
            >
                {options.map((variant, index) => (
                    <MenuItem value={variant} key={index}>
                        <Checkbox
                            checked={(value || []).findIndex((item) => item.id === variant.id) >= 0}
                        />
                        <ListItemText primary={variant.name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}