import React from "react";
import { Box, Button, IconButton, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import Field from "components/Field";
import { email, required } from "components/Field/validate";
import { useDispatch } from "react-redux";
import { LoginwithFierbase, login } from "store/actions/auth";
import SwipperContainer from "components/AuthSideBar";
import history from "components/history";
import { screenAnalytics, signInWithApple, signInWithGooglePopup } from "fierbase";

const LoginForm = () => {
    React.useEffect(() => {
        screenAnalytics('Login');
    }, []);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logGoogleUser = async () => {
        const response = await signInWithGooglePopup();
        if (response) {
            return dispatch(LoginwithFierbase(response))
        }
    }

    const logAppleUser = async () => {
        const response = await signInWithApple();
        if (response) {
            return dispatch(LoginwithFierbase(response))
        }
    }
    return (
        <Grid
            item
            lg={7}
            md={12}
            sx={{
                width: "100%",
                margin: "auto",
                display: "flex",
            }}
        >
            <Stack
                sx={{ width: "100%", gap: { xs: "30px", md: "30px" } }}
                display="flex"
                alignItems="center"
                gap="96px"
            >
                <Stack width="100%">
                    <Typography component="span" variant="h3">
                        Log in
                    </Typography>
                    <Typography
                        component="span"
                        variant="h6"
                        color="info.main"
                        sx={{ mt: 1 }}
                    >
                        Welcome back! Please enter your details.
                    </Typography>
                </Stack>
                <Stack width="100%">
                    <Formik
                        initialValues={{
                            Email: "",
                            Password: "",
                        }}
                        onSubmit={(values, { resetForm }) => {
                            return dispatch(login(values, resetForm))
                        }}
                    >
                        {(props) => {
                            const { handleSubmit, isSubmitting } = props;
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Field
                                        type="text"
                                        inputLabel="Email"
                                        placeholder="Enter your email"
                                        name="Email"
                                        validate={email}
                                    />
                                    <Field
                                        type="password"
                                        inputLabel="Password"
                                        placeholder="Enter Password here..."
                                        name="Password"
                                        validate={required}
                                    />
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: 600,
                                            cursor: "pointer",
                                            mb: 3,
                                        }}
                                        onClick={() => navigate("/forget-password")}
                                    >
                                        {" "}
                                        Forgot password
                                    </Typography>
                                    <Stack width="100%" mb={2}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            disabled={isSubmitting}
                                        >
                                            {" "}
                                            Sign In
                                        </Button>


                                    </Stack>
                                </form>
                            );
                        }}
                    </Formik>
                    <Typography
                        align="center"
                        variant="body2"
                        sx={{
                            fontWeight: 600,
                            mb: 1,
                        }}
                    >
                        or continue with
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent='center' sx={{
                        mb: 3,
                    }}>
                        <IconButton aria-label="google" size="small" onClick={() => logGoogleUser()}>
                            <img src="/assets/logo/google.svg" alt="google" />
                        </IconButton>
                        <IconButton aria-label="apple" size="small" onClick={() => logAppleUser()}>
                            <img src="/assets/logo/apple.svg" alt="apple" />
                        </IconButton>
                    </Box>
                    <Typography
                        align="center"
                        variant="body2"
                        sx={{
                            fontWeight: 600,
                            cursor: "pointer",
                            mb: 3,
                        }}
                        onClick={() => history.push("/privacy-policy")}
                    >
                        {" "}
                        Privacy Policy
                    </Typography>
                    <Box sx={{ display: { xs: "block", md: "none" } }}>
                        <SwipperContainer />
                    </Box>
                    <Typography
                        component="span"
                        textAlign="center"
                        variant="body2"
                        color="info.main"
                        fontWeight="400"
                    >
                        Don’t have an account?
                        <Typography
                            component="span"
                            variant="body2"
                            color="info.main"
                            fontWeight="600"
                            sx={{ cursor: "pointer" }}
                            ml={1}
                            onClick={() => navigate("/signup")}
                        >
                            {" "}
                            Sign up
                        </Typography>
                    </Typography>
                </Stack>
            </Stack>
        </Grid>
    );
};

export default LoginForm;
