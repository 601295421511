import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import useResponsive from "utils/hooks/useResponsive";
import Scrollbar from "utils/Scrollbar";
import NavSection from "layout/NavItems";
import LogoutIcon from "@mui/icons-material/Logout";
import Cookie from "utils/cookie";
import { useSelector } from "react-redux";
import { stringAvatar } from "utils/logic";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 272;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const openProfilePage = () => {
    if (Cookie.get("userType") === "User") {
      navigate("/profile");
    }
  };

  const renderContent = (
    <>
      <Scrollbar
        sx={{
          height: "calc(100vh - 88px)",
          "& .simplebar-content": {
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", py: 3 }}>
          <img
            src="/assets/logo/Logo.svg"
            alt="logo"
            height="150px"
            onClick={() => {
              navigate("/dashboard");
            }}
            style={{
              cursor: "pointer"
            }}
          />
        </Box>
        <NavSection />
      </Scrollbar>
      <Box>
        <Stack alignItems="flex-start" sx={{ position: "relative" }} >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            width="100%"
            sx={{
              p: 3,
              gap: 2,
              borderTop: "1px solid rgba(234, 236, 240, 1)",
              width: "90%",
              margin: "auto",
            }}
          >
            <Avatar
              onClick={() => openProfilePage()}
              sx={{ cursor: "pointer" }}
              src={user?.ProfileImage}
            >
              {stringAvatar(user?.Username.toUpperCase())}
            </Avatar>
            <Box sx={{ cursor: "pointer" }}>
              <Typography
                variant="subtitle2"
                fontWeight="fontWeightMedium"
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "130px",
                }}
              >
                {user?.Username || 'No Data Found'}
              </Typography>
              <Typography
                variant="subtitle2"
                fontWeight="fontWeightRegular"
                color="info.main"
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "130px",
                }}
              >
                {user?.Email || 'No Data Found'}
              </Typography>
            </Box>
            <IconButton
              onClick={() => {
                Cookie.delete("jwt");
                Cookie.delete("userId");
                navigate("/login");
              }}
            >
              <LogoutIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Box>
    </>
  );

  return (
    <RootStyle>
      {isDesktop ? (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              borderRight: "1px solid #EAECF0",
              height: "100%",
              backgroundImage: "url(./assets/images/sidebar.svg)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center bottom",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              backgroundImage: "url(./assets/images/sidebar.svg)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center bottom",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
