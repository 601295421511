export const required = (value) => (value ? "" : "This Field is Required");
export const multiSelect = (value) =>
  (value || []).length !== 0 ? "" : "This Field is Required";
export const password = (value) =>
  (value || []).length === 0
    ? "This Field is Required"
    : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/.test(value)
    ? ""
    : "Password Must be 8+ chars, incl. uppercase, lowercase, number & special char.";
export const email = (value = "") =>
  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Email is not valid"
    : undefined;
export const matchesPassword = (value, NewPassword) =>
    value === NewPassword
        ? undefined
        : 'Conform Password must be same as Password';