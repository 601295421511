import moment from "moment-timezone";
import toast from "react-hot-toast";

export const StringReducer = (str, maxLength) => {
    if (str !== null) {
        if (str.length > maxLength) {
            return str.substring(0, maxLength) + "...";
        } else {
            return str
        }
    } else {
        return 'N/A'
    }
};


export const numberFormat = (value) => {
    if (value == null) {
        return '$0.00';
    }
    const numericValue = Number(value);
    if (isNaN(numericValue)) {
        return '$0.00';
    }
    const absoluteValue = Math.abs(numericValue);
    if (absoluteValue >= 1e6 && absoluteValue < 1e9) {
        return `$${(absoluteValue / 1e6).toFixed(2).toLocaleString()}M`;
    } else if (absoluteValue >= 1e9) {
        return `$${(absoluteValue / 1e9).toFixed(2).toLocaleString()}B`;
    } else {
        return `$${absoluteValue.toFixed(2).toLocaleString()}`;
    }
};

export const shareProfileHandler = async (url, text = 'Link copied to clipboard', message = '') => {
    if (window.navigator.share) {
        try {
            await window.navigator.share({
                title: message,
                text: message,
                url: url,
            });
            toast.success(text);
        } catch (error) {
            console.error("Error sharing", error);
        }
    } else {
        const copyText = message ? `${message} ${url}` : url;
        try {
            await window.navigator.clipboard.writeText(copyText);
            toast.success(text);
        } catch (error) {
            console.error("Error copying to clipboard", error);
        }
    }
};


export const numberFormate = (value) => value && Number(value).toFixed(2).toLocaleString();

export const stringAvatar = (name) => {
    if (!name) return "FT";
    const words = name.split(" ");
    let initials = "";
    for (const word of words) {
        if (word.length > 0) {
            initials += word[0].toUpperCase();
        }
    }
    return initials;
}


export const formatPlayDate = (date)  => {
    if (!date) {
        return 'N/A';
    }
    const mountainTimeOffset = moment().isDST() ? -6 : -7;
    const momentDate = moment.utc(date || new Date()).startOf('day');
    const now = moment().utcOffset(mountainTimeOffset).startOf('day');
    const yesterday = moment().utcOffset(mountainTimeOffset).startOf('day').subtract(1, 'days');
    if (momentDate.isSame(now, 'day') || momentDate === now) {
      return 'Today';
    } else if (momentDate.isSame(yesterday, 'day')) {
      return 'Yesterday';
    } else {
      return momentDate.format('MM/DD/YYYY');
    }
  }

export const colorCodeDatewise = (value) => value === 'Today' ? '#02c716' : value === 'Yesterday' ? '#fcba03' : '#D50000'

