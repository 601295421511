import React from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Field from "components/Field";
import { email } from "components/Field/validate";
import { Formik } from "formik";
import axios from "axios";
import toast from "react-hot-toast";
import axiosError from "utils/axiosError";
import Cookie from "../../../utils/cookie";
import { screenAnalytics } from "fierbase";

const ForgetPasswordPage = () => {

  React.useEffect(() => {
    screenAnalytics('ForgotPassword');
  }, []);

  const navigate = useNavigate();
  return (
    <Grid
      item
      lg={7}
      md={12}
      sx={{
        width: "100%",
        margin: "auto",
        display: "flex",
      }}
    >
      <Stack
        sx={{ width: "100%", gap: { xs: "30px", md: "30px" } }}
        display="flex"
        alignItems="center"
        gap="96px"
      >
        {/* <Stack width="100%">
          <Typography
            component="span"
            sx={{
              cursor: "pointer",
            }}
          >
            <img
              src="/assets/logo/Logo.svg"
              alt="google"
              height="250"
              onClick={() => navigate("/dashboard")}
            />
          </Typography>
        </Stack> */}
        <Stack width="100%">
          <Typography component="span" variant="h3">
            Forgot Password
          </Typography>
          <Typography
            component="span"
            variant="h6"
            color="info.main"
            sx={{ mt: 1 }}
          >
            Enter your email here
          </Typography>
        </Stack>
        <Stack width="100%">
          <Formik
            initialValues={{
              Email: "",
            }}
            onSubmit={(values, { resetForm }) => {
              return axios
                .post("/api/user/forgetPasswordEmail", values)
                .then((res) => {
                  toast.success(res?.data?.message);
                  Cookie.save("userId", res?.data?.UserID);
                  navigate("/reset-password")
                  resetForm();
                })
                .catch((err) => axiosError(err));
            }}
          >
            {(props) => {
              const { handleSubmit, isSubmitting } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Field
                    type="text"
                    inputLabel="Email"
                    placeholder="Enter your email"
                    name="Email"
                    validate={email}
                  />
                  <Stack width="100%" mb={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </Stack>
                </form>
              );
            }}
          </Formik>
          <Typography
            component="span"
            textAlign="center"
            variant="body2"
            color="info.main"
            fontWeight="400"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => navigate("/login")}
          >
            Cancel
          </Typography>
        </Stack>
      </Stack>
    </Grid>
  );
};

export default ForgetPasswordPage;
