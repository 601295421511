import React from "react";
import { connect } from "formik";
import {
  Breadcrumbs,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Cookie from "utils/cookie";

function ItemWithButtons({ title, subtitle, breadcrumbs }) {
  return (
    <Stack
      direction="row"
      alignItems="baseline"
      justifyContent="space-between"
      width="100%"
      zIndex="1"
    >
      <Stack direction="column">
        <Typography variant="h3" sx={{
          fontSize: { xs: "16px", sm: "22px" }
        }}>{title}</Typography>
        <Typography variant="subtitle1" color="info.main" fontWeight="400" sx={{ fontSize: { xs: "12px", sm: "16px" } }}>
          {" "}
          {subtitle}
        </Typography>
        {breadcrumbs && (
          <Breadcrumbs
            sx={{ mt: 2, display: { xs: "none", sm: "block" } }}
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        )}
      </Stack>
    </Stack>
  );
}


const NavHeader = ({ location }) => {
  const navigate = useNavigate();
  const isResultPage = window.location.pathname === "/results"
  const userData = useSelector((state) => state.auth.viewUserData);
  const viewGroupData = useSelector((state) => state.auth.viewGroupData);
  const { id, userId, deeplink } = useParams();
  const userType = Cookie.get("userType");

  let item = null;
  let titleLists = {
    [`/user/${id}`]: {
      t: "Profile",
      s: "View and share profile information, including previous scores.",
      n: "User",
    },
    [`/update-group/${id}`]: {
      t: "Update Group",
      s: "View and modify trivia group",
      n: "Groups",
    },
    [`/group/${id}`]: {
      t: "Group",
      s: "View and modify trivia group",
      n: "Groups",
    },
    [`/join-group/${id}/${deeplink}`]: {
      t: "Group",
      s: "View and modify trivia group",
      n: "Groups",
    },
    [`/group/${id}/${userId}`]: {
      t: "Profile",
      s: "View and share profile information, including previous scores.",
      n: "Groups",
    },
    [`/question/${id}`]: {
      t: "Question List",
      s: "Manage questions for all users",
      n: "Question List",
    },
    "/question": {
      t: "Question List",
      s: "Manage questions for all users",
      n: "Add Question",
    },
    [`/question/${id}/attended-users`]: {
      t: "Attended Users",
      s: "View users who participated in the quiz",
      n: "Attended Users",
    },
    "/dashboard": {
      t: "Play",
      s: "Play Your today quiz",
      n: "Question List",
    },
    "/results": {
      t: "Results",
      s: "View and share your weekly results",
      n: "Results",
    },
    "/user": {
      t: "User",
      s: "View and share user profile information and scores",
      n: "User",
    },
    "/leaderboard": {
      t: "Leaderboard",
      s: "View this month's leaders across the world",
      n: "Leaderboard",
    },
    "/support": {
      t: "Support",
      s: "Create a ticket to submit a bug or feature request.",
      n: "Support",
    },
    [`/support/closed-inquiries/${id}`]: {
      t: "Closed Inquiries",
      s: "Completed help desk inquiry",
      n: "Closed Inquiries",
    },
    "/support/closed-inquiries": {
      t: "Closed Inquiries",
      s: "Completed help desk inquiries",
      n: "Closed Inquiries",
    },
    [`/support/${id}`]: {
      t: "Helpdesk",
      s: "Create a helpdesk ticket to submit a bug or feature request.",
      n: "Helpdesk",
    },
    [`/leaderboard/${id}`]: {
      t: "Profile",
      s: "View and share user profile information and scores",
      n: "Leaderboard",
    },
    "/play-game": {
      t: "Play",
      s: "Today trivia game covering all major sports.",
      n: "Play",
    },
    "/group": {
      t: "Groups",
      s: "Create, edit and view your trivia groups.",
      n: "Groups",
    },
    "/profile": {
      t: "Account",
      s: "View and modify profile data, including previous scores.",
      n: "Account",
    },
    "/admins": {
      t: "FanatIQ Trivia Userbase",
      s: "View signed up Admins",
      n: "Admin",
    },
    "/create-group": {
      t: "Group",
      s: "Create, edit and view your trivia groups.",
      n: "Group",
    },
  };
  if (titleLists[location]) {
    const breadcrumbs = [
      <IconButton
        key="2"
        display="flex"
        alignItems="center"
        onClick={() => {
          if (!isResultPage) {
            navigate("/dashboard");
          }
        }}
        disabled={isResultPage}
      >
        <img src="/assets/logo/homeLine.svg" alt="home" />
      </IconButton>,
      <Typography
        key="2"
        variant="subtitle2"
        color="primary.main"
        fontWeight="fontWeightMedium"
        sx={{
          background: "#D0D5DD",
          padding: "4px 8px",
          borderRadius: "6px",
          cursor: "pointer"
        }}
        onClick={() => {
          if (window.location.pathname.includes("/user/") && userType === 'User') {
            return
          }
          const url = new URL(location, "https://example.com");

          const route = "/" + url.pathname.split("/")[1];
          {
            window.location.pathname.includes("/question/") ?
              navigate("/dashboard")
              :
              window.location.pathname.includes("/update-group/") ?
                navigate("/group") :
                navigate(route);
          }
        }}
      >
        {
          window.location.pathname.includes("dashboard") && userType !== 'User' ? 'Question List' :
            titleLists[location].n}
      </Typography>,

      ((["/join-group/", "/update-group/"].some(substring => window.location.pathname.includes(substring)) && viewGroupData) && (
        <Typography
          key="2"
          variant="subtitle2"
          color="primary.main"
          fontWeight="fontWeightMedium"
          onClick={() => {
            const url = new URL(location, "https://example.com");
            const route = "group/" + url.pathname.split("/")[2];
            navigate(route);
          }}
          sx={{
            background: "#D0D5DD",
            padding: "4px 8px",
            borderRadius: "6px",
            cursor: "pointer"
          }}
        >
          {viewGroupData?.GroupID.substring(0, 5)}
        </Typography>
      )),


      (id && !(["/group/", "/join-group/", "/update-group/"].some(substring => window.location.pathname.includes(substring))) && (
        <Typography
          key="2"
          variant="subtitle2"
          color="primary.main"
          fontWeight="fontWeightMedium"
          onClick={() => {
            if (window.location.pathname.includes("/user/") && userType === 'User') {
              return;
            }
            if (window.location.pathname.includes('/question/')) {
              return;
            }
            const url = new URL(location, "https://example.com");
            const route = "/" + url.pathname.split("/")[1];
            navigate(route);
          }}
          sx={{
            background: "#D0D5DD",
            padding: "4px 8px",
            borderRadius: "6px",
            cursor: "pointer"
          }}
        >
          {["/question/", "/support/closed-inquiries/", "/support/"].some(substring => window.location.pathname.includes(substring)) ? id.substring(0, 5) : userData?.Username}
        </Typography>
      ))
    ];
    item = (
      <ItemWithButtons
        title={window.location.pathname.includes("dashboard") && userType !== 'User' ? 'Question List' : titleLists[location]?.t}
        subtitle={window.location.pathname.includes("dashboard") && userType !== 'User' ? 'Manage questions for all users' : titleLists[location]?.s}
        breadcrumbs={breadcrumbs}
      />
    );
  }

  return item;
};

export default connect(NavHeader);
