// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          background: "#FF9500",
          borderRadius: "8px",
          textTransform:"capitalize",
          fontWeight:600,
          fontSize:"14px"
        },
        sizeSmall:{
          fontSize:"0.8125rem",
          padding:"5px 10px"
        },
        outlinedInfo:{
          background: "rgba(145, 158, 171, 0.24)",
          color: "rgba(145, 158, 171, 0.8)",
          boxShadow: "none",
        },
        containedError: {
          borderRadius: "44px"
        },
      },
    },
  };
}
