import React from 'react';
import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import toast from "react-hot-toast";
import { stringAvatar } from 'utils/logic';

const UploadFile = ({
    validateFileType,
    isLoading = false,
    profilePhoto,
    accept,
    isSubmitBtn = true,
    handlefileChange,
    isEditProfileImage = true,
    userName
}) => {
    const iconStyle = {
        alignItems: 'center',
        background: '#002B42',
        border: '2px solid #fff',
        borderRadius: '50%',
        bottom: '3px',
        display: 'flex',
        height: '24px',
        justifyContent: 'center',
        position: 'absolute',
        right: '100%',
        width: '24px',
        left: "85px",
        top: "80px"
    };
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (validateFileType) {
            if (validateFileType(file)) {
                !isSubmitBtn && handlefileChange(file);
            } else {
                toast.error("Invalid file type. Please choose a different file.");
            }
        } else {
            !isSubmitBtn && handlefileChange(file);
        }
    };

    return (
        <Box style={{ display: "flex", gap: "20px", position: "relative" }}>
            <div style={{ position: "relative", width: 90, height: 90, marginTop: "10px" }}>
                <img
                    src="/assets/images/profileBorder.svg" alt="border"
                    style={{
                        position: "absolute",
                        width: "calc(100% + 20px)",
                        height: "calc(100% + 20px)",
                        borderRadius: "50%"
                    }}
                />
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                    }}
                >
                    {isLoading ? (
                        <Box
                            style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                borderRadius: "50%",
                                top: "40%",
                                left: 10
                            }}
                        >
                            <Typography display="flex" sx={{ justifyContent: "center" }}>
                                <CircularProgress sx={{ height: 50, width: 50 }} />
                            </Typography>
                        </Box>
                    ) : (
                        <>
                            {profilePhoto ? (
                                <img
                                    src={profilePhoto}
                                    style={{
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "50%",
                                        top: "10px",
                                        left: "10px"
                                    }}
                                    alt={profilePhoto}
                                />
                            ) : (
                                <Avatar
                                    style={{
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "50%",
                                        top: "10px",
                                        left: "10px"
                                    }}
                                >
                                    {stringAvatar(userName)}
                                </Avatar>
                            )}
                        </>
                    )}
                </div>
            </div>
            {isEditProfileImage && (
                <div style={iconStyle}>
                    <input
                        type="file"
                        id="file"
                        name="file"
                        accept={accept}
                        style={{
                            position: "absolute",
                            opacity: 0,
                            pointerEvents: "none",
                            width: "100%",
                            height: "100%",
                            zIndex: 1
                        }}
                        onChange={handleFileChange}
                        disabled={isLoading}
                    />
                    <label htmlFor="file" style={{ position: "relative", cursor: "pointer", zIndex: 0, display: "flex" }}>
                        <ModeEditOutlineOutlinedIcon fontSize="1rem" style={{ color: "white" }} />
                    </label>
                </div>
            )}
        </Box>
    );
};

export default UploadFile;