import React from 'react';
import { NavLink as RouterLink, useLocation, } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { styled } from "@mui/material/styles";
import { routesConfig } from "../config";
import Cookie from "../../utils/cookie";

const ListItemStyle = styled((props) => (
    <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
    ...theme.typography.body2,
    height: "40px",
    position: "relative",
    textTransform: "capitalize",
    color: "#344054",
    fontWeight: 600,
    "&:hover": {
        background: "#F9FAFB",
    }
}));

const ListItemIconStyle = styled(ListItemIcon)({
    width: 22,
    height: 22,
    color: "#344054",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});
// ----------------------------------------------------------------------

NavItem.propTypes = {
    item: PropTypes.object,
    active: PropTypes.func,
};

function NavItemLabel(props) {
    return (
        <ListItemText
            disableTypography
            primary={props.title}
            sx={{
                textTransform: "uppercase",
                padding: "10px 20px",
                color: "#7A7A7A",
                fontWeight: 600,
                mt: 3, mb: 1
            }}
        />
    )
}

function NavItem({ item, active }) {
    const isActiveRoot = active(item.references);
    const { title, path, icon, info, children } = item;

    const activeRootStyle = {
        background: "#fef1df",
    };

    if (item.headerLabel) {
        return (
            <NavItemLabel title={item.headerLabel} />
        )
    }

    return (
        <ListItemStyle
            component={RouterLink}
            to={path}
            sx={{
                height: "40px",
                position: "relative",
                mb: 1,
                ...(isActiveRoot && activeRootStyle),
            }}
        >
            <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
            <ListItemText disableTypography primary={title} />
            {info && info}
        </ListItemStyle>
    );
}

const NavSection = () => {
    const userType = Cookie.get("userType");
    const { pathname } = useLocation();
    const match = (path) => {
        return path.some((route)=> pathname.includes(route))
    };

    return (
        <Box>
            <List disablePadding sx={{ px: 2 }}>
                {(routesConfig[userType] || []).map((item, idx) => (
                    <NavItem key={idx} item={item} active={match} />
                ))}
            </List>
        </Box>
    );
};

export default NavSection;
